import React from 'react';
import VisibilitySensor from "../visibilitySensor";

import "./title-with-logo.scss";

import CeresLogo from "../../images/svg/ceres-icon.svg";

// if necessary to add to gatsby-ssr, follow this guide: https://markoskon.com/scroll-reveal-animations-with-react-spring/#ssr-with-javascript-disabled

const TitleWithLogo = (props) => {
  return (
    <VisibilitySensor>
      {({ isVisible }) => (
        <h2 className={`title-with-logo ${isVisible ? 'fadeIn sensorEnter' : 'fadeIn'}`}>
          <span>{props.title}</span>
          <CeresLogo/>
        </h2>
      )}
    </VisibilitySensor>
  )
};

export default TitleWithLogo;
