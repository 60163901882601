import React, {useEffect, useState} from 'react';
import VisibilitySensor from "../visibilitySensor";
import axios from 'axios';
import CONST from "../../config/constants";
import { OutboundLink } from "gatsby-plugin-google-gtag"
import TitleWithLogo from "../titleWithLogo"
import contents from "./onTheWeb.yml";
import Swiper from "react-id-swiper";
import "./onTheWeb.scss";

import FacebookIcon from "../../images/svg/facebook.svg";
import InstagramIcon from "../../images/svg/instagram.svg";

const OnTheWeb = () => {

  const [instagramPosts,setInstagramPosts] = useState([]);
  const [facebookPosts,setFacebookPosts] = useState([]);
  const [success,setSuccess] = useState(false);
  const [isLoading,setIsLoading] = useState(false);

  const howmanyPosts = 5;

  useEffect(() => {

    const _i = [];
    const _f = [];
    setIsLoading(true);
    axios.get(`${CONST.JUICER_URL}&r=${Math.floor(Math.random() * 999999999)}`)
      .then(function (response) {
        response.data.posts.items.forEach((post)=>{
          if(post.source && post.source.source && post.source.source.toLowerCase() === 'facebook') {
            _f.push(post);
          }
          if(post.source && post.source.source && post.source.source.toLowerCase() === 'instagram') {
            _i.push(post);
          }
          setSuccess(true);
        });
        setInstagramPosts(_i.slice(0,howmanyPosts));
        setFacebookPosts(_f.slice(0,howmanyPosts));
      })
      .catch(function (error) {
        console.warn(error);
        setSuccess(false);
      })
      .then(function () {
        setIsLoading(false);
      });

  },[]);

  return (
    <>
      { success &&
        <div className={`OnTheWeb ${isLoading ? 'is-loading' : ''}`}>
          <TitleWithLogo
            title={contents.title}
          />
          <div className="row">
            <VisibilitySensor>
              {({ isVisible }) => (
                <div className={`col col-facebook ${isVisible ? 'slideUp sensorEnter' : 'slideUp'}`}>
                  <SocialTitle link={contents.facebookUrl} title={contents.facebook}/>
                  { facebookPosts && facebookPosts.length &&
                    <SocialSwiper source="facebook" items={facebookPosts}/>
                  }
                </div>
              )}
            </VisibilitySensor>
            <VisibilitySensor>
              {({ isVisible }) => (
                <div className={`col col-instagram ${isVisible ? 'slideUp sensorEnter' : 'slideUp'}`}>
                  <SocialTitle link={contents.instagramUrl} title={contents.instagram}/>
                  { instagramPosts && instagramPosts.length &&
                  <SocialSwiper source="instagram" items={instagramPosts}/>
                  }
                </div>
              )}
            </VisibilitySensor>
          </div>
        </div>
      }
    </>
  )
};

const SocialTitle = ({title, link}) => {
  return(
    <h3>
      {/*<OutboundLink*/}
      {/*  href={link}*/}
      {/*  target="_blank"*/}
      {/*  rel="noopener noreferrer"*/}
      {/*>*/}
        {title}
      {/*</OutboundLink>*/}
    </h3>
  );
};


const SocialSwiper = ({source,items}) => {

  const [swiper, updateSwiper] = useState(null);

  const pause = () => {
    if (swiper !== null && swiper.autoplay.running) {
      swiper.autoplay.stop();
    }
  };
  const resume = () => {
    if (swiper !== null && !swiper.autoplay.running) {
      swiper.autoplay.start();
    }
  };

  const params = {
    spaceBetween: 0,
    slidesPerView: 1,
    simulateTouch: false,
    allowTouchMove: false,
    preventInteractionOnTransition: true,
    speed: 1000,
    autoplay: {
      delay: 5000,
      disableOnInteraction: true,
    },
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    }
  };

  return (
    <Swiper
      containerClass={`swiper-container--${source}`}
      getSwiper={updateSwiper}
      {...params}
    >
      {
        items.map( (post,i) => {
          // console.log(post);
          return (
            <div key={i}>
              <OutboundLink
                href={post.external || post.full_url || contents.facebookUrl}
                target="_blank"
                rel="noopener noreferrer"
                // eventLabel={`Post ${source}`}
              >
                <div
                  className="post-image"
                  onMouseOver={()=>pause()}
                  onFocus={()=>pause()}
                  onMouseLeave={()=>resume()}
                  role="presentation"
                  style={{
                    backgroundImage: `url(${post.image})`
                  }}
                >
                  <div className="post-content">
                    <div className="inner">
                      { source === 'facebook' &&
                        <FacebookIcon/>
                      }
                      { source === 'instagram' &&
                        <InstagramIcon/>
                      }
                      <p dangerouslySetInnerHTML={{
                        __html: post.unformatted_message
                      }}/>
                    </div>
                  </div>
                </div>
              </OutboundLink>
            </div>
          );
        })
      }
    </Swiper>
  );


};


export default OnTheWeb;
